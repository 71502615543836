<template>
  <VFlex grow>
    <VLayout align-space-around row fill-height>
      <VFlex
        v-if="!isForemanColumnHidden"
        shrink
        :class="[
          'cal-cell foreman-cell',
          { 'inactive-foreman': foreman.STATUS !== 'Active' },
        ]"
        ><span class="crew-leader-name text-truncate"
          >{{ crewLeaderName
          }}<i v-if="inactiveForeman"><br />(Inactive)</i></span
        ></VFlex
      >
      <CalCell
        v-for="(n, index) in numCells"
        :key="n"
        :reports="getReportsForDate(index)"
        :is-sunday="isSunday(index)"
        :is-weekend="isWeekend(index)"
        :is-in-the-past="isInThePast(index)"
        :inactive-foreman="inactiveForeman"
        :foreman="foreman"
        :index="index"
        :num-sundays="numSundays"
        :total-dates-visible="numCells"
        :is-last-cell="index === numCells - 1"
        @reportAdded="reportAdded({ foreman, index, draggedObj: $event })"
        @createGhost="createGhost(foreman, index, $event)"
      />
    </VLayout>
  </VFlex>
</template>

<script>
import dayjs from 'dayjs'
import { createHelpers } from 'vuex-map-fields'
import { mapGetters } from 'vuex'

const CalCell = () =>
  import(/* webpackChunkName: 'CalCell' */ '@scheduler/CalCell')
const { mapFields } = createHelpers({
  getterType: 'getSchedulerField',
  mutationType: 'updateSchedulerField',
})
export default {
  name: 'WeekBody',
  components: {
    CalCell,
  },
  props: {
    foreman: {
      type: Object,
      required: true,
    },
    week: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getIsPrintMode']),
    ...mapFields(['isForemanColumnHidden']),
    numCells() {
      return this.getIsPrintMode ? 14 : 8
    }, // numCells
    crewLeaderName() {
      let parts = this.foreman.LABORER_NAME.split(' ')
      let name = parts[0]
      if (parts[1]) {
        name += ` ${parts[1][0]}${parts[1].length > 1 ? '.' : ''}`
      }
      return name
    }, // crewLeaderName
    inactiveForeman() {
      return this.foreman.STATUS !== 'Active'
    }, // inactiveForeman
    numSundays() {
      const start = dayjs(this.startDate)
      let numSundays = 0
      for (let i = 0; i < this.numCells; i++) {
        const currentDate = start.add(i, 'day')
        if (currentDate.day() === 0) {
          // Day.js uses 0 for Sunday
          numSundays++
        }
      }
      return numSundays
    }, // numSundays
  }, // computed
  methods: {
    reportAdded(foreman, index, location) {
      this.$emit('reportAdded', foreman, index, location)
    }, // reportAdded
    isWeekend(index) {
      let day = dayjs(this.startDate)
        .add(index, 'day')
        .day()
      return [6,0].includes(day)
    }, // isWeekend
    isSunday(index) {
      let day = dayjs(this.startDate)
        .add(index, 'day')
        .day()
      return [0].includes(day)
    }, // isSunday

    isInThePast(index) {
      let isInThePast = dayjs(this.startDate, 'MM-DD-YYYY')
        .add(index, 'day')
        .isBefore(dayjs().format('MM-DD-YYYY'))
      return isInThePast
    }, // isInThePast
    getReportsForDate(index) {
      let calDate = dayjs(this.startDate, 'MM-DD-YYYY')
        .add(index, 'day')
        .format('MM/DD/YYYY')
      return this.week.filter((r) => r.REPORT_DATE.date === calDate)
    }, // getReportsForDate
    createGhost(foreman, index, report) {
      this.$emit('createGhost', foreman, index, report)
    }, // createGhost
  },
}
</script>

<style lang="scss">
.foreman-cell {
  display: flex;
  min-width: 80px;
  min-height: 20px;
  padding: 5px;
  font-size: 1rem;
  font-weight: 500;
  background: lighten(black, 85%);
  border-color: darken(#d2d2d2, 6);
  border-bottom-width: 0;

  @media only screen and (max-width: 992px) {
    font-size: 0.8rem;
  }

  @media print {
    font-size: 0.7rem;
    font-weight: bold;
    min-width: 80px;
    padding: 0 !important;
  }

  &.inactive-foreman {
    color: #4a4a4a;
    background: lighten(black, 75%);
  }

  .cal-row.last & {
    border-bottom-width: 1px;
  }
}
</style>
